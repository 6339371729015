/*
 |-----------------------------------------------------------------------------
 | pages/[slug]/index.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import { get, map } from 'lodash';
import { Helmet } from 'react-helmet';
// import { ray } from 'node-ray/web';
import { useTina } from 'tinacms/dist/edit-state';

import Aos from '@/vendor/Aos';
import Blocks from '@/layouts/Blocks';
import Layout from '@/layouts/Layout';
import { client } from '@/tina/__generated__/client';
import { setState } from '@/utils/data';
import { useAppDispatch } from '@/app/hooks';

interface IProps {
	collections: object;
	data: {
		menuConnection: object;
		organisation: object;
		page: object;
	};
	query: string;
	slug: string;
	variables: object;
}

const Page: NextPage<IProps> = props => {
	// ray(`Debug template [slug]:`, get(props, 'data')).green();

	const dispatch = useAppDispatch();

	const { data } = useTina({
		data: get(props, 'data'),
		query: get(props, 'query'),
		variables: get(props, 'variables'),
	});

	const { menuConnection, organisation, page } = data;

	useEffect(() => {
		setState(
			'Page',
			get(props, 'collections'),
			dispatch,
			menuConnection,
			organisation,
			page
		);
	}, [dispatch, menuConnection, organisation, page, props]);

	return (
		<Aos>
			<Helmet>
				<body itemScope itemType="http://schema.org/WebPage" />
			</Helmet>

			<Layout>
				<Blocks blocks={get(page, 'blocks')} />
			</Layout>
		</Aos>
	);
};

export const getStaticPaths: GetStaticPaths = async () => {
	try {
		const { data } = await client.queries.pageConnection();
		// ray('Debug data:', data).green();

		const paths = map(get(data, ['pageConnection', 'edges']), page => {
			return {
				params: {
					slug: get(page, ['node', '_sys', 'filename']),
				},
			};
		});
		// ray('Debug paths:', paths).green();

		return { paths, fallback: false };
	} catch (err) {
		Sentry.captureException(err);
		return null;
	}
};

export const getStaticProps: GetStaticProps = async context => {
	const { slug } = get(context, 'params');

	try {
		const response = await client.queries.pageQuery({
			relativePath: `${slug}.mdx`,
		});
		// ray('Debug response:', response).green();

		const collections = get(response, ['data', 'page', 'collections']);
		// ray('Debug collections:', collections).green();

		let articles,
			certifications,
			events,
			jobs,
			patrons,
			people,
			posts,
			products,
			projects,
			recipes,
			reviews,
			services,
			stockists = {};
		if (collections) {
			await Promise.all(
				Object.keys(collections).map(async collection => {
					switch (collection) {
						case 'article':
							if (collections[collection]) {
								const { data } =
									await client.queries.articleConnection({
										sort: 'date',
										...(slug !== 'articles' && {
											first: 6,
										}),
									});

								articles = {
									articles: get(data, [
										'articleConnection',
										'edges',
									]),
								};
							}
							break;
						case 'certification':
							if (collections[collection]) {
								const { data } =
									await client.queries.certificationConnection(
										{
											sort: 'order',
										}
									);

								certifications = {
									certifications: get(data, [
										'certificationConnection',
										'edges',
									]),
								};
							}
							break;
						case 'event':
							if (collections[collection]) {
								const { data } =
									await client.queries.eventConnection({
										// sort: 'schedule.startDate',
										...(slug !== 'events' && { first: 6 }),
									});

								events = {
									events: get(data, [
										'eventConnection',
										'edges',
									]),
								};
							}
							break;
						case 'job':
							if (collections[collection]) {
								const { data } =
									await client.queries.jobConnection({
										sort: 'date',
										...(slug !== 'jobs' && { first: 6 }),
									});

								jobs = {
									jobs: get(data, ['jobConnection', 'edges']),
								};
							}
							break;
						case 'patron':
							if (collections[collection]) {
								const { data } =
									await client.queries.patronConnection({
										sort: 'order',
									});

								patrons = {
									patrons: get(data, [
										'patronConnection',
										'edges',
									]),
								};
							}
							break;
						case 'person':
							if (collections[collection]) {
								const { data } =
									await client.queries.personConnection({
										sort: 'order',
										...(slug !== 'people' && { first: 6 }),
									});

								people = {
									people: get(data, [
										'personConnection',
										'edges',
									]),
								};
							}
							break;
						case 'post':
							if (collections[collection]) {
								const { data } =
									await client.queries.postConnection({
										sort: 'date',
										...(slug !== 'posts' && { first: 6 }),
									});

								posts = {
									posts: get(data, [
										'postConnection',
										'edges',
									]),
								};
							}
							break;
						case 'product':
							if (collections[collection]) {
								const { data } =
									await client.queries.productConnection({
										sort: 'order',
										...(slug !== 'products' && {
											first: 6,
										}),
									});

								products = {
									products: get(data, [
										'productConnection',
										'edges',
									]),
								};
							}
							break;
						case 'project':
							if (collections[collection]) {
								const { data } =
									await client.queries.projectConnection({
										sort: 'date',
										...(slug !== 'projects' && {
											first: 6,
										}),
									});

								projects = {
									projects: get(data, [
										'projectConnection',
										'edges',
									]),
								};
							}
							break;
						case 'recipe':
							if (collections[collection]) {
								const { data } =
									await client.queries.recipeConnection({
										sort: 'order',
										...(slug !== 'recipes' && { first: 6 }),
									});

								recipes = {
									recipes: get(data, [
										'recipeConnection',
										'edges',
									]),
								};
							}
							break;
						case 'review':
							if (collections[collection]) {
								const { data } =
									await client.queries.reviewConnection({
										sort: 'date',
										...(slug !== 'reviews' && { first: 6 }),
									});

								reviews = {
									reviews: get(data, [
										'reviewConnection',
										'edges',
									]),
								};
							}
							break;
						case 'service':
							if (collections[collection]) {
								const { data } =
									await client.queries.serviceConnection({
										sort: 'order',
										...(slug !== 'services' && {
											first: 6,
										}),
									});

								services = {
									services: get(data, [
										'serviceConnection',
										'edges',
									]),
								};
							}
							break;
						case 'stockist':
							if (collections[collection]) {
								const { data } =
									await client.queries.stockistConnection({
										sort: 'order',
									});

								stockists = {
									stockists: get(data, [
										'stockistConnection',
										'edges',
									]),
								};
							}
							break;
					}
				})
			);
		}

		return {
			props: {
				collections: {
					...articles,
					...certifications,
					...events,
					...jobs,
					...patrons,
					...people,
					...posts,
					...products,
					...projects,
					...recipes,
					...reviews,
					...services,
					...stockists,
				},
				data: get(response, 'data'),
				query: get(response, 'query'),
				variables: get(response, 'variables'),
			},
		};
	} catch (err) {
		Sentry.captureException(err);
		return { props: null };
	}
};

export default Page;
